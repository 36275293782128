html {
    overflow-y: scroll;
    overflow-wrap: break-word;
}

body, html {
    height: 100%;
}

strong, th {
    font-weight: $font-weight-bold;
}

small {
    font-weight: $font-weight-light;
    color: $custom-blue;
}

p.lead {
    line-height: 1.2;
}

.intro-icon {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin-left: -.4rem;
}

.container-fluid {
    h2 {
        span {
            color: $custom-blue;
        }
    }

    &.blue {
        background-color: $custom-blue;
        color: $white;

        a {
            color: $white;
        }

        h2 {
            span {
                display: inline-block;
                color: $white;
                font-weight: $font-weight-light;
                line-height: 1.2;
            }
        }

        .container {
            padding: 0;
        }
    }

    &.cyan {
        background-color: $custom-cyan;
        color: $white;
        a, a:hover {
            color: $white;
        }

        h2 {
            color: $custom-blue;

            span {
                display: inline-block;
                color: $white;
                font-weight: $font-weight-light;
                line-height: 1.2;
            }
        }

        .container {
            background-color: $custom-cyan;
            padding: 0;
        }
    }

    &.white {
        .container {
            padding: 0;
        }
    }
}

.col-md-6.img, .col.img {
    padding: 0;

    &.right {
        padding-right: 15px;
    }

    &.left {
        padding-left: 15px;
    }

    .icon {
        background-color: $white;
    }
}

.col.logos {
    a {
        display: inline-block;

        img {
            margin: .25rem;
            max-width: 100%;
        }
    }
}

#logo-evf {
    background: linear-gradient(to bottom, #2468b8 33%, #123e72 100%);
    padding: 2rem;
}

footer {
    background-color: $custom-blue;
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: $white;

    a {
        color: $white;
        text-decoration: none;

        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }
}
