@include media-breakpoint-up(lg) {
    .navbar {
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar .has-megamenu {
        position: static !important;
    }
    .navbar .megamenu {
        left: 0;
        right: 0;
        width: 100%;
        padding: 20px;
    }
    .navbar .nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu.megamenu {
        position: fixed;
        top: 79px;
    }
}

@include media-breakpoint-only(md) {
    #helpdesk {
        top: 4rem;
        right: 15px;
        width: 80px;
        height: 80px;
    }
    .container-fluid.blue, .container-fluid.white, .container-fluid.cyan {
        .container {
            max-width: 100%;
        }
    }
    .col-12.img, .col.img {
        &.right {
            padding-right: 0;
            padding-left: 0;
        }

        &.left {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .container-fluid.blue, .container-fluid.white, .container-fluid.cyan {
        .container {
            max-width: 100%;
        }
    }
    header {
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }

        nav {
            ul.navbar-nav {
                height: 100vh;
                overflow-y: scroll;
                padding-bottom: 5rem;
            }
        }
    }
    #helpdesk {
        top: 4rem;
        right: 15px;
        width: 80px;
        height: 80px;
    }
    .col-12.img, .col.img {
        &.right {
            padding-right: 0;
            padding-left: 0;
        }

        &.left {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@include media-breakpoint-down(xs) {
    .megamenu {
        h2 {
            font-size: 2rem;
        }
    }
    nav .nav-item a {
        font-size: .8rem;
    }
}
