header {
    background-color: $white;

    .container {
        padding: 0;
    }
}

nav {
    &.navbar {
        padding: 0;
    }

    .navbar-brand {
        position: relative;
        max-width: 25%;
        flex: 0 0 25%;
    }

    .navbar-toggle {
        margin-right: 5px;

        .icon-bar {

            width: 28px;
            height: 3px;
        }
    }

    .navbar-collapse {
        margin-top: 1rem;
    }

    .nav-item {

        a {
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
            border-bottom: 3px solid transparent;
            transition: border-bottom 500ms;
        }

        &.active a.nav-link, a.nav-link:hover {
            border-bottom: 3px solid $custom-blue;
        }
    }
}

.smart-scroll {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.scrolled-down {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
}

.scrolled-up {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.megamenu {
    a {
        border-bottom: 0;
        text-decoration: none;
    }

    h2 {
        font-weight: $font-weight-bold;
        font-size: 4rem;
    }

    a.dropdown-item:active, a.dropdown-item:focus {
        color: $custom-cyan;
    }

    .row {
        width: 100%;

        hr {
            margin: .25rem;
            border-top-color: $gray-400;
        }
    }
}

#helpdesk {
    top: 3rem;
    right: -100px;
    position: absolute;
    color: $custom-cyan;
    background-color: transparent;
    width: 100px;
    height: 100px;
    z-index: 1040;

    div {
        border-radius: 50%;
        background-color: $custom-cyan;
        box-shadow: 0 0 15px 0 rgba(0,0,0,0.75);
        img {
            margin: 5px;
            transition: transform 1s;
            &:hover {
                transform: rotate(30deg);
            }
        }
    }
}
